import React from "react";
import { Image } from "react-bootstrap";

const AboutUsHeroSection = (props) => {
  const { images } = props;
  return (
    <section className="aboutus-herosection">
      <div>
        <Image src={images.image1.heroImg} fluid className="section-img" />
      </div>
      <div>
        <h5>About O'Democracy</h5>
      </div>
    </section>
  );
};

export default AboutUsHeroSection;
