import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import Logo from "../../assets/images/footer-logo.png";
import ListGroup from "react-bootstrap/ListGroup";
import { FaSquareFacebook } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { Link, useLocation } from "react-router-dom";

function Footer() {
  const location = useLocation();
  const noFooterPaths = [
    "/dashboard",
    "/manage-party",
    "/party-history",
    "/manage-associated-candidate-by-id",
    "/add-associated-candidate-by-id",
    "/manage-candidate",
    "/candidate-history",
    "/manage-province",
    "/manage-associated-district-by-id",
    "/add-province",
    "/edit-province",
    "/manage-districts",
    "/add-districts",
    "/edit-districts",
    "/polling-division",
    "/add-poling-division",
    "/edit-poling-division",
    "/add-party",
    "/edit-party-by-id",
    "/add-candidate",
    "/edit-candidate-by-id",
    "/user-list",
    "/edit-user-by-id",
    "/type-of-election",
    "/add-election-type",
    "/edit-election-type",
    "/type-of-election-candidate",
    "/type-of-election-add-candidate",
    "/type-of-election-edit-candidate",
    "/security-level",
    "/manage-permission",
    "/add-securities",
    "/edit-securities",
    "/permission",
    "/add-permission",
    "/edit-permission",
  ];

  if (noFooterPaths.includes(location.pathname)) {
    return null;
  }

  return (
    <div className="footer-main ">
      <Container fluid className="px-0">
        <Row md={3} xs={1} className="mx-0 row1">
          <Col className="px-0">
            <Image className="logo" src={Logo}></Image>
            <p className="footer-p mt-3">
              Lorem ipsum dolor sit amet consectetur adipiscing elit. Proin
              finibus mauris est, in mattis leo lacinia non.
            </p>
          </Col>
          <Col className="px-0 mt-md-0 mt-4">
            <ListGroup className="border-0">
              <ListGroup.Item className="footer-election">
                ELECTION
              </ListGroup.Item>
              <ListGroup.Item className="footer-electionP">
                Election Monitoring
              </ListGroup.Item>
              <ListGroup.Item className="footer-electionP">
                Election History
              </ListGroup.Item>
              <ListGroup.Item className="footer-electionP">
                Election Commission
              </ListGroup.Item>
              <ListGroup.Item className="footer-electionP">
                Electoral Map
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col className="px-0 mt-md-0 mt-4">
            <h6 className="stay-connect">STAY CONNECT WITH US</h6>
            <p className="list-group-item">
              Beycome Florida 400 NW 26th St, Miami
            </p>
            <div className="d-flex align-items-center justify-content-between w-lg-25 w-50">
              <FaSquareFacebook className="facebook" />
              <FaLinkedin className="facebook" />
              <FaSquareXTwitter className="facebook" />
            </div>
          </Col>
        </Row>
        <div className="footer-Dis">
          <Link to={"/about-us"} className="text-decoration-none">
            About us
          </Link>
          <Link to={"/blogs"} className="text-decoration-none">
            Blogs
          </Link>
          <Link to={"/election-results"} className="text-decoration-none">
            Election Results
          </Link>
          <Link to={"/reports"} className="text-decoration-none">
            Reports
          </Link>
          <Link to={"/candidates"} className="text-decoration-none">
            Candidates
          </Link>
          <Link to={"/parties"} className="text-decoration-none">
            Parties
          </Link>
          <Link to={"/simulator"} className="text-decoration-none">
            Vote Simulator
          </Link>
          <Link to={"/terms-conditions"} className="text-decoration-none">
            Terms & Conditions
          </Link>
        </div>
        <div className="row1">
          <p className="disclaimer">
            Disclaimer: O'Democracy Realty Services Limited is only an
            intermediary offering its platform to advertise properties of Seller
            for a Customer/Buyer/User coming on its Website and is not and
            cannot be a party to or privy to or control in any manner any
            transactions between the Seller and the Customer/Buyer/User. All the
            offers and discounts on this Website have been extended by Read
            more.
          </p>
        </div>
      </Container>
    </div>
  );
}

export default Footer;
