import React, { useEffect } from "react";
import heroImg from "../../../assets/images/heroImg.png";
import src5 from "../../../assets/svg/arrow.svg";
import src6 from "../../../assets/svg/wavylines.svg";
import src10 from "../../../assets/svg/Learn and improvement.svg";
import src11 from "../../../assets/svg/unique.svg";
import src13 from "../../../assets/svg/Eye.svg";
import src14 from "../../../assets/svg/Mission.svg";
import src15 from "../../../assets/svg/Promis.svg";
import src16 from "../../../assets/svg/empowerment.svg";
import src17 from "../../../assets/svg/technique.svg";
import src18 from "../../../assets/svg/global-marketing.svg";
import src19 from "../../../assets/svg/last.svg";
import { MdOutlineCampaign } from "react-icons/md";
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { indexGetAllBlogContent } from "../../../redux/adminone/action";
import AboutUsHeroSection from "../../../components/user/aboutUsMain/aboutUsHeroSection";
import SectionAbout from "../../../components/user/landingPage/sectionAbout";
import SectionVisionMission from "../../../components/user/landingPage/sectionVisionMission";
import NonPartisanAndApproach from "../../../components/user/aboutUsMain/nonPartisanAndApproach";
import OurCommitment from "../../../components/user/aboutUsMain/ourCommitment";
import SectionNews from "../../../components/user/landingPage/sectionNews";

const AboutUsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(indexGetAllBlogContent());
  }, [dispatch]);

  const handleClick = () => {
    navigate("/blogs");
  };

  const getAllBlogContent = useSelector(
    (state) => state.adminone.getAllBlogContent?.data
  );

  const props = {
    titles: {
      title3: "Vision Statement",
      title4: "Mission Statement",
      title5: "How It Works",
      title6:
        "O'Democracy is more than just a platform—it’s a commitment to the core values that uphold democracy worldwide.",
      title7:
        "O'Democracy is a forward-thinking, fostering transparency, and strengthening democratic processes around the globe.",
      title8: "Non-Partisan and Unbiased",
      title9: "Our Scientific Approach",
      title10: "LATEST BLOGS",
      title11: "Objectives",
      title12: "Our Commitment",
    },
    lables: {
      lable1: "Our Commitment to Democracy",
      lable2: "Who We Are",
    },
    categoryName: {
      cName0: getAllBlogContent?.[0]?.category_name || "Default Category",
      cName1: getAllBlogContent?.[1]?.category_name || "Default Category",
      cName2: getAllBlogContent?.[2]?.category_name || "Default Category",
      cName3: getAllBlogContent?.[3]?.category_name || "Default Category",
      cName4: getAllBlogContent?.[4]?.category_name || "Default Category",
    },
    subtitles: {
      heading5: "Promote Informed Participation:",
      heading6: "Advance Transparency:",
      heading7: "Leverage Innovation and Technology:",
      heading8: getAllBlogContent?.[0]?.blog_title || "Default Title",
      heading9: getAllBlogContent?.[1]?.blog_title || "Default Title",
      heading10: getAllBlogContent?.[2]?.blog_title || "Default Title",
      heading16: getAllBlogContent?.[3]?.blog_title || "Default Title",
      heading17: getAllBlogContent?.[4]?.blog_title || "Default Title",
      heading18: "Foster Global Democratic Values:",
    },
    children: {
      children4: "Read more",
      children5: "View All",
    },
    images: {
      image1: { heroImg },
      image5: getAllBlogContent?.[0]?.blog_image ?? "Default image",
      image6: getAllBlogContent?.[1]?.blog_image ?? "Default image",
      image7: getAllBlogContent?.[2]?.blog_image ?? "Default image",
      image8: getAllBlogContent?.[3]?.blog_image ?? "Default image",
      image9: getAllBlogContent?.[4]?.blog_image ?? "Default image",
    },

    paras: {
      para1:
        "We envision a world where democracy thrives through the active involvement of citizens who are well-informed and fully engaged in their governance. O'Democracy strives to be a global catalyst for positive change, using technology to amplify voices and build stronger democratic institutions that serve all of humanity.",

      para2:
        "Our mission is to promote democratic participation by providing a platform that enables open dialogue, transparent data sharing, and community-driven decision-making. We aim to empower every individual with the tools and knowledge necessary to influence elections, shape policies, and contribute to a more just and equitable society.",
      para8: `O'Democracy utilizes a range of scientific methods to ensure accuracy and objectivity in our analyses and predictions. Through data-driven insights, algorithmic modeling, and soon-to-be-integrated AI-powered features, we ensure that the information you receive is both precise and reliable. We apply statistical models to predict election outcomes, taking into account variables such as voter demographics, historical data, and real-time shifts in political trends. This enables our users to engage with reliable projections and simulations, helping them better understand the complexities of democratic systems.
Additionally, our O'Democracy Simulator offers users the ability to experiment with various electoral scenarios, using scientific and data-based methods to simulate different election outcomes and voter behaviors.`,
      para9:
        "At O'Democracy, neutrality and fairness are at the core of everything we do. We are a non-partisan platform with no affiliation to any political party, group, or ideology. Our goal is to provide objective, fact-based information that helps citizens form their own opinions and participate in democratic processes free from bias. Whether you’re exploring candidate profiles or simulating election outcomes, you can trust that the data and insights we provide are purely grounded in fact and designed to foster informed decision-making.",
      para10: "O'Democracy Simulator: Explore the Future of Elections",
      para14: getAllBlogContent?.[0]?.overview || "Default overview",
      para15: getAllBlogContent?.[1]?.overview || "Default overview",
      para16: getAllBlogContent?.[2]?.overview || "Default overview",
      para17: `O'Democracy is a forward-thinking, non-partisan platform committed to empowering citizens, fostering transparency, and strengthening democratic processes around the globe. We provide an innovative space where users can engage with the political landscape, explore election dynamics, and actively participate in shaping the future of governance. Our platform is built on the principles of fairness, inclusivity, and transparency, making it a trusted space for everyone—whether you're a voter, researcher, or political enthusiast.`,
      para18: `O'Democracy is more than just a platform—it’s a commitment to the core values that uphold democracy worldwide. Through innovation, transparency, and a dedication to fairness, we aim to inspire hope and progress, building a future where democracy thrives on the active, informed participation of its citizens.`,
      para19:
        "At O'Democracy, we believe that democracy is at its strongest when citizens are informed, engaged, and empowered to shape their own futures. We are dedicated to creating an inclusive digital platform where individuals from every corner of the world can participate in meaningful political discussions, access critical information, and directly contribute to the democratic process.",
      para21: `Equip users with data and insights to better understand candidates, parties, and policies, fostering well-informed voter engagement.`,
      para22: `Create an open, accessible space where election data, analysis, and predictions are available to all, ensuring clarity in political processes.`,
      para23: `Utilize advanced algorithms, data analytics, and AI (coming soon) to provide users with real-time insights, dynamic simulations, and accurate electoral predictions.`,
      para24: `Support and promote the core values of democracy—freedom, equality, justice, and accountability—by empowering citizens and encouraging participation across the world.`,
      para25: `Use the O'Democracy Simulator to educate yourself on electoral dynamics and share your findings with others. Whether you're a voter, researcher, political enthusiast, or a party strategist, the simulator provides a powerful way to visualize and understand how elections could unfold based on various decisions.`,
    },
    icons: {
      icons6: <MdOutlineCampaign />,
      icons7: src5,
      icons8: src6,
      icons12: <BsArrowRight />,
      icons13: src10,
      icons14: src11,
      icons16: src13,
      icons17: src14,
      icons18: src15,
      icons19: src16,
      icons20: src17,
      icons21: src18,
      icons22: src19,
    },
    dates: {
      date1: getAllBlogContent?.[0]?.created_at || "Default Date",
      date2: getAllBlogContent?.[1]?.created_at || "Default Date",
      date3: getAllBlogContent?.[2]?.created_at || "Default Date",
      date4: getAllBlogContent?.[3]?.created_at || "Default Date",
      date5: getAllBlogContent?.[4]?.created_at || "Default Date",
    },
    onClick: {
      handleClick,
      onClick0: () =>
        navigate("/full-blogs", { state: { id: getAllBlogContent?.[0]?.id } }),
      onClick1: () =>
        navigate("/full-blogs", { state: { id: getAllBlogContent?.[1]?.id } }),
      onClick2: () =>
        navigate("/full-blogs", { state: { id: getAllBlogContent?.[2]?.id } }),
      onClick3: () =>
        navigate("/full-blogs", { state: { id: getAllBlogContent?.[3]?.id } }),
      onClick4: () =>
        navigate("/full-blogs", { state: { id: getAllBlogContent?.[4]?.id } }),
    },
  };

  return (
    <main className="about-us-page">
      <AboutUsHeroSection {...props} />
      <SectionAbout {...props} />
      <SectionVisionMission {...props} />
      <NonPartisanAndApproach {...props} />
      <OurCommitment {...props} />
      <SectionNews {...props} />
    </main>
  );
};

export default AboutUsPage;
