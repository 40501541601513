import React from "react";
import { Image } from "react-bootstrap";

const NonPartisanAndApproach = (props) => {
  const { paras, titles, icons } = props;
  return (
    <section className="nonPartisan-approach">
      {/* <aside className="section-aside">
        <div className="eduvoter-block">
          <h2 className="eduvotertitle">{titles.title8}</h2>
          <Image src={icons.icons8} className="eduvoterwavyline" />
          <p className="eduvoterpara">{paras.para9}</p>
        </div>
      </aside> */}
      {/* <aside className="section-aside">
        <div className="eduvoter-block">
          <h2 className="eduvotertitle">{titles.title9}</h2>
          <Image src={icons.icons8} className="eduvoterwavyline" />
          <p className="eduvoterpara">{paras.para8}</p>
        </div>
      </aside> */}

      <aside className="section-vmBlk">
        <div className="d-flex pb-4">
          <Image src={icons.icons13} className="ba-icon" />
          <h2 className="vmblk-heading ps-3">{titles.title8}</h2>
        </div>
        <p className="vmblk-para">{paras.para9}</p>
      </aside>
      <aside className="section-vmBlk">
        <div className="d-flex pb-4">
          <Image src={icons.icons14} className="ba-icon" />
          <h2 className="vmblk-heading ps-3">{titles.title9}</h2>
        </div>
        <p className="vmblk-para">{paras.para8}</p>
      </aside>
    </section>
  );
};

export default NonPartisanAndApproach;
