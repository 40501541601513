import React from "react";
import { Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import OrangeSolidBtn from "../../buttons/orangeSolidBtn";

const OurCommitment = (props) => {
  const { children, paras, titles, lables, icons } = props;
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/");
  };
  return (
    <section className="our-commitment">
      <aside className="section-aside">
        <span className="aside-lable">
          {icons.icons6}
          {lables.lable1}
        </span>
        <h2 className="aside-heading">{titles.title6}</h2>
        <Image src={icons.icons7} className="float-end" />
      </aside>
      <aside className="section-aside">
        <p className="aside-para">{paras.para18}</p>
        <OrangeSolidBtn onClick={onClick} children={children.children4} />
      </aside>
    </section>
  );
};

export default OurCommitment;
